import * as React from "react";
import { ContentsParams } from "../gwangju-types";
import Sibling from "../components/sibling";
import { Helmet } from "react-helmet";
import { navigate } from "gatsby";

interface ContentProps {
  contentsParameters: ContentsParams;
}

export default class Content extends React.Component<ContentProps> {
  public render() {
    const mapDescription = (data: ContentsParams) => {
      if (data.description == null) {
        return <Helmet></Helmet>;
      } else {
        return (
          <Helmet>
            <meta name="description" content={data.description} />
          </Helmet>
        );
      }
    };

    return (
      <div>
        {mapDescription(this.props.contentsParameters)}
        <div>
          <h1 className="bottom-auto w-full p-1 text-4xl font-medium">
            {this.props.contentsParameters.title}
          </h1>
          <div className="flex flex-wrap w-full p-1">
            <span className="p-1">
              {" 🕐 " + this.props.contentsParameters.date}
            </span>
            <span className="p-1">
              {this.props.contentsParameters.updatedAt != null
                ? " 🕓 " + this.props.contentsParameters.updatedAt
                : ""}
            </span>
            <button
              className="p-1 hover:text-blue-500"
              onClick={(event) => {
                navigator.clipboard.writeText(window.location.href);
              }}
            >
              {" 📑 " + this.props.contentsParameters.uuid}
            </button>
          </div>
          <div className="bottom-auto w-full p-1 text-sm">
            <Sibling siblings={this.props.contentsParameters.siblings} />
          </div>
        </div>
        <br />
        <div className="bottom-auto w-full p-2">
          <main>
            <div
              className="markdown-body text-base px-1"
              dangerouslySetInnerHTML={{
                __html: this.props.contentsParameters.contents,
              }}
            ></div>
          </main>
        </div>
      </div>
    );
  }
}
