import { PageRendererProps } from "gatsby";
import * as React from "react";
import Layout from "../layouts/layout";
import Content from "../components/content";

interface PostTemplateProps extends PageRendererProps {
  pageContext: any;
}

export default class PostTemplate extends React.Component<PostTemplateProps> {
  public render() {
    let layoutTitle = this.props.pageContext.contentsParams.title;
    if (this.props.pageContext.contentsParams.fullTitle != null) {
      layoutTitle = this.props.pageContext.contentsParams.fullTitle;
    }

    return (
      <Layout
        layoutParams={this.props.pageContext.layoutParams}
        contentsTitle={layoutTitle}
      >
        <Content contentsParameters={this.props.pageContext.contentsParams} />
      </Layout>
    );
  }
}
